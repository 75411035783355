/************ Skandinavisk colors ***********/
/******* Aspect ratios *****/
/****** Transition *******/
.banner-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1980px;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 1199px) {
  .banner-content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .banner-content.center-middle {
    text-align: center;
  }
  .banner-content, .banner-content.center-middle, .banner-content.center-right, .banner-content.center-left {
    justify-content: center;
  }
  .banner-content.bottom-middle, .banner-content.top-middle {
    align-items: center;
  }
  .banner-content.center-left, .banner-content.bottom-left, .banner-content.top-left {
    align-items: flex-start;
  }
  .banner-content.center-right, .banner-content.bottom-right, .banner-content.top-right {
    align-items: flex-end;
  }
  .banner-content.bottom-right, .banner-content.bottom-left, .banner-content.bottom-middle {
    justify-content: flex-end;
    margin-bottom: 60px;
  }
  .banner-content.top-right, .banner-content.top-left, .banner-content.top-middle {
    justify-content: flex-start;
    margin-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-content.right-mobile {
    text-align: right;
  }
}
@media only screen and (max-width: 767px) {
  .banner-content.left-mobile {
    text-align: left;
  }
}
@media only screen and (max-width: 767px) {
  .banner-content.center-mobile {
    text-align: center;
  }
}

.banner-title,
.banner-subtitle {
  word-break: break-word;
}

.banner-title.small {
  font-size: 30px;
  line-height: 34px;
}
@media only screen and (max-width: 767px) {
  .banner-title.small {
    font-size: 16px;
    line-height: 20px;
  }
}
.banner-title.medium {
  font-size: 36px;
  line-height: 40px;
}
@media only screen and (max-width: 767px) {
  .banner-title.medium {
    font-size: 22px;
    line-height: 30px;
  }
}
.banner-title.large {
  font-size: 46px;
  line-height: 50px;
}
@media only screen and (max-width: 767px) {
  .banner-title.large {
    font-size: 30px;
    line-height: 34px;
  }
}
.banner-title.extra-large {
  font-size: 56px;
  line-height: 60px;
}
@media only screen and (max-width: 767px) {
  .banner-title.extra-large {
    font-size: 38px;
    line-height: 42px;
  }
}

.banner-subtitle {
  margin-top: 15px;
}
.banner-subtitle.small {
  font-size: 18px;
  line-height: 25px;
}
@media only screen and (max-width: 767px) {
  .banner-subtitle.small {
    font-size: 16px;
    line-height: 20px;
  }
}
.banner-subtitle.medium {
  font-size: 30px;
  line-height: 35px;
}
@media only screen and (max-width: 767px) {
  .banner-subtitle.medium {
    font-size: 18px;
    line-height: 25px;
  }
}
.banner-subtitle.large {
  font-size: 40px;
  line-height: 45px;
}
@media only screen and (max-width: 767px) {
  .banner-subtitle.large {
    font-size: 30px;
    line-height: 35px;
  }
}
.banner-subtitle.extra-large {
  font-size: 45px;
  line-height: 50px;
}
@media only screen and (max-width: 767px) {
  .banner-subtitle.extra-large {
    font-size: 30px;
    line-height: 35px;
  }
}

.banner-cta {
  padding-top: 16px;
}
.banner-cta .btn {
  min-width: 150px;
}
@media only screen and (min-width: 768px) {
  .banner-cta .btn:nth-child(2) {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-cta .btn {
    margin-bottom: 10px;
  }
}

.light {
  color: #fff;
}

.dark {
  color: #000;
}

.animate-img {
  -webkit-animation: fadeInImg 1s ease-in;
  animation: fadeInImg 1s ease-in;
  -webkit-animation-delay: 0.1;
  animation-delay: 0.1;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

@keyframes fadeInImg {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.animate-content {
  -webkit-animation: fadeInText 2s ease-in;
  animation: fadeInText 2s ease-in;
  -webkit-animation-delay: 0.5;
  animation-delay: 0.5;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}